import React from "react";

import SkeletonWrapper from "./SkeletonWrapper";

const BrandCardSkeleton: React.FC = () => (
  <SkeletonWrapper width="237" height="247">
    <rect x="49" y="12" rx="0" ry="0" width="141" height="140" />
    <rect x="21" y="174" rx="0" ry="0" width="195" height="28" />

    <rect x="49" y="223" rx="0" ry="0" width="141" height="24" />
  </SkeletonWrapper>
);

export default BrandCardSkeleton;
