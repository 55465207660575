import React from "react";
import { trackEvent } from "@leafly-com/web-utils";

import { MenuItem } from "custom-types/MenuItem";
import {
  Dispensary,
  MenuItemCard as MenuItemCardType,
  Product,
  Strain,
} from "custom-types/MenuItemCard";
import { Offer } from "custom-types/Offer";

import MenuItemCard from "components/botanic/MenuItemCard";
import DispensaryMenuItemCard from "components/DispensaryMenuItemCard/DispensaryMenuItemCard";
import { MenuItemCardActions } from "components/Shared/MenuItemCarousel/MenuItemCardActions";

type Props = {
  campaignId?: number;
  dispensaryId: number;
  displayStaffPick?: boolean;
  menuItem: MenuItem;
  onAddToBagClick?: () => void;
  onClick?: () => void;
  oneCardPerRow?: boolean;
  onReservation?: () => void;
};

export interface MenuItemWithIndicators {
  isBestSeller?: boolean;
  isNew?: boolean;
  isStaffPick?: boolean;
  isTrending?: boolean;
}

export const getMenuItemIndicator = (
  menuItem: MenuItemWithIndicators,
  displayStaffPick?: boolean,
) => {
  if (!menuItem) {
    return;
  }

  const { isTrending, isBestSeller, isNew, isStaffPick } = menuItem;

  let cardFlagText: string | undefined;
  let showStaffPick: boolean | undefined;
  if (isTrending) {
    cardFlagText = "Trending now";
  } else if (isBestSeller) {
    cardFlagText = "Best seller";
  } else if (isNew) {
    cardFlagText = "New";
  } else if (isStaffPick && displayStaffPick) {
    showStaffPick = true;
    cardFlagText = "Staff pick";
  }

  return { cardFlagText, showStaffPick };
};

const MenuCard: React.FC<Props> = ({
  campaignId,
  dispensaryId,
  displayStaffPick,
  menuItem,
  onAddToBagClick = () => {},
  onClick = () => {},
  oneCardPerRow,
  onReservation = () => {},
}) => {
  const { brand, deal, menuItemId, product, strain, onlineFulfillmentEnabled } =
    menuItem;
  const menuCardIndicators = getMenuItemIndicator(menuItem, displayStaffPick);
  const { id: brandId } = brand || {};
  const { id: dealId } = deal || {};
  const { id: productId } = product || {};
  const { id: strainId } = strain || {};

  const handleCardClick = () => {
    onClick();
    trackEvent(
      "Dispensary Menu",
      "click",
      `Detail View - ${
        onlineFulfillmentEnabled ? "Reservable" : "Not Reservable"
      }`,
      {
        brandId,
        dealId,
        dispensaryId,
        menuItemId,
        productId,
        strainId,
        ...(campaignId && { merchandisingCampaignId: campaignId }),
      },
    );

    if (menuCardIndicators?.cardFlagText) {
      trackEvent(
        "menu_card_indicator",
        "click",
        menuCardIndicators?.cardFlagText,
      );
    }
    if (menuItem?.offers?.length) {
      const { dispensary, offers } = menuItem;
      const label = `${dispensary.name} - ${dispensary.id} (#) - ${
        offers?.length > 1
          ? "Multiple Offers"
          : `${offers[0].title} - ${offers[0].id}`
      }`;
      trackEvent("Dispensary Menu", "Click", label);
    }
  };

  // The below is translating the MenuItem to a MenuItemCard as
  // we are trying to standardize the types used for the menu item card
  // components instead of supporting many different types. Once the menu
  // is refactored to use the new api (menu items v3) then this will not be needed.
  const showDeal =
    menuItem.deal?.active &&
    menuItem.deal?.discountAmount &&
    menuItem.deal?.discountedPrice;

  const offerLabelDisplay = (offers: Offer[] | undefined) => {
    if (offers?.length === 1) {
      return offers[0].shortDisplayTitle;
    } else {
      return `${offers?.length} offers`;
    }
  };
  const offerLabel =
    !showDeal && menuItem.offers && menuItem.offers?.length > 0
      ? offerLabelDisplay(menuItem.offers)
      : null;

  const menuItemCard = {
    brand: null,
    brandName: menuItem.brandName,
    category: menuItem.productCategory,
    cbdContentLabel: menuItem.cbdContentLabel,
    dealDiscountLabel: showDeal && menuItem.deal?.discountLabel,
    dispensary: {
      country: menuItem.dispensary.country,
      deliveryEnabled: menuItem.dispensary.deliveryEnabled,
      distanceMi: menuItem.dispensary.distanceMi,
      id: menuItem.dispensary.id,
      inStoreCartEnabled: menuItem.dispensary.inStoreCartEnabled,
      logoUrl: menuItem.dispensary.logoUrl,
      maxDeliveryDurationMaximum: 0,
      maxOrderMinimum: 0,
      name: menuItem.dispensary.name,
      path: menuItem.dispensary.path,
      pickupEnabled: menuItem.dispensary.pickupEnabled,
      slug: menuItem.dispensary.slug,
    } as Dispensary,
    displayPrice: menuItem.sortPrice,
    id: menuItem.menuItemId,
    isBestSeller: menuItem.isBestSeller,
    isBrandVerified: menuItem.isBrandVerified,
    isNew: menuItem.isNew,
    isStaffPick: menuItem.isStaffPick,
    isTrending: menuItem.isTrending,
    name: menuItem.name,
    offerLabel: offerLabel,
    onlineFulfillmentEnabled: menuItem.onlineFulfillmentEnabled,
    price: menuItem.price,
    product: {
      avgApprovedPublicReviewRating:
        menuItem.product?.avgApprovedPublicReviewRating,
      countApprovedPublicReviews: menuItem.product?.countApprovedPublicReviews,
      id: menuItem.product?.id,
      slug: "",
    } as Product,
    quantity: menuItem.quantity,
    strain: {
      id: menuItem.strain?.id,
      phenotype: menuItem.strain?.phenotype,
      slug: menuItem.strain?.slug,
    } as Strain,
    thcContentLabel: menuItem.thcContentLabel,
    thumbnailUrl: menuItem.thumbnailUrl,
    unit: menuItem.unit,
    variantId: menuItem.id, // menuItem.id represents id for variant shown on card
  } as MenuItemCardType;

  return oneCardPerRow ? (
    <DispensaryMenuItemCard
      menuItem={menuItemCard}
      menuCardIndicators={menuCardIndicators}
      handleCardClick={handleCardClick}
      onAddToBagClick={onAddToBagClick}
      onReservation={onReservation}
    />
  ) : (
    <MenuItemCard
      handleCardClick={handleCardClick}
      menuItem={menuItem}
      menuCardIndicators={menuCardIndicators}
    >
      <MenuItemCardActions
        menuItem={menuItem}
        onClick={onAddToBagClick}
        onReservation={onReservation}
        merchandisingCampaignId={campaignId}
      />
    </MenuItemCard>
  );
};

export default MenuCard;
