import React from "react";

import SkeletonWrapper from "./SkeletonWrapper";

type CheckBoxSkeletonProps = {
  width?: string;
  height?: string;
  className?: string;
};

const CheckBoxSkeleton: React.FC<CheckBoxSkeletonProps> = ({
  width = "100%",
  height = "24",
  className,
}) => (
  <div className="p-xs">
    <SkeletonWrapper width={width} height={height} className={className}>
      <rect x="0" y="0" rx="4" ry="4" width="24" height="24" />
      <rect x="30" y="2" rx="4" ry="4" width={width} height="18" />
    </SkeletonWrapper>
  </div>
);
export default CheckBoxSkeleton;
