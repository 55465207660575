import React from "react";

import SkeletonWrapper from "./SkeletonWrapper";

const PillSkeleton: React.FC<{ className?: string }> = ({ className }) => (
  <SkeletonWrapper width="85" height="26" className={className}>
    <rect x="0" y="0" rx="16" ry="16" width="85" height="26" />
  </SkeletonWrapper>
);

export default PillSkeleton;
