import React from "react";

import SkeletonWrapper from "./SkeletonWrapper";

const DispensaryCardSkeleton: React.FC = () => (
  <SkeletonWrapper width="325" height="214">
    <rect x="12" y="12" rx="0" ry="0" width="64" height="64" />
    <rect x="84" y="12" rx="0" ry="0" width="228" height="32" />
    <rect x="84" y="52" rx="0" ry="0" width="228" height="28" />
    <rect x="84" y="88" rx="0" ry="0" width="180" height="24" />
    <rect x="84" y="116" rx="0" ry="0" width="200" height="18" />
    <rect x="84" y="142" rx="0" ry="0" width="200" height="18" />

    <rect x="92" y="190" rx="0" ry="0" width="141" height="24" />
  </SkeletonWrapper>
);

export default DispensaryCardSkeleton;
