import React from "react";

import SkeletonWrapper from "./SkeletonWrapper";

type TileSkeletonProps = {
  width?: string;
  height?: string;
  className?: string;
};

const TileSkeleton: React.FC<TileSkeletonProps> = ({
  width = "100%",
  height = "64",
  className,
}) => (
  <SkeletonWrapper width={width} height={height} className={className}>
    <rect x="0" y="0" rx="4" ry="4" width={width} height={height} />
  </SkeletonWrapper>
);

export default TileSkeleton;
