import React, { MouseEventHandler } from "react";
import classnames from "classnames";
import capitalize from "lodash/capitalize";
import Link from "next/link";
import { ReactNode } from "react-markdown";

import { Category } from "constants/events";
import { Dispensary, MenuItemCard } from "custom-types/MenuItemCard";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import currencyFormatter from "utils/currencyFormatter";
import { formatQuantity } from "utils/formatQuantity";
import { getDynamicMenuItemUrl } from "utils/menuItemUrl";

import Button from "components/botanic/Button";
import CardFlag from "components/botanic/Card/CardFlag";
import StarRating from "components/botanic/StarRating";
import Verified from "components/Icons/verified.svg";
import Image from "components/Image";

import OfferLabel from "../OfferLabel";
import { ATBMenuItem } from "./AddToBagButton";
import { MenuItemCardActions } from "./MenuItemCardActions";

const DispensaryMenuItemCard: React.FC<{
  menuItem: MenuItemCard;
  menuCardIndicators?: {
    cardFlagText: string | undefined;
    showStaffPick: boolean | undefined;
  };
  handleCardClick: MouseEventHandler<HTMLAnchorElement>;
  onAddToBagClick?: () => void;
  onAlternateAddToBagClick?: () => void;
  onReservation?: () => void;
  renderFulfillmentInfo?: (dispensary: Dispensary) => ReactNode;
  useAlternateAddToBagAction?: boolean;
  eventCategory?: Category;
}> = ({
  menuItem,
  menuCardIndicators,
  handleCardClick,
  onAddToBagClick,
  onAlternateAddToBagClick,
  onReservation,
  renderFulfillmentInfo,
  useAlternateAddToBagAction = false,
  eventCategory,
}) => {
  const {
    id,
    brand,
    brandName,
    category,
    cbdContentLabel,
    dealDiscountLabel,
    displayPrice,
    dispensary,
    isBrandVerified,
    name,
    offerLabel,
    onlineFulfillmentEnabled,
    price,
    product,
    quantity,
    strain,
    thcContentLabel,
    thumbnailUrl,
    unit,
    variantId,
  } = menuItem;

  const { phenotype = "" } = strain ?? {};

  const menuItemUrl = getDynamicMenuItemUrl(
    { menuItemId: id, name },
    dispensary.path,
    variantId,
  );

  const productTypeText =
    category === "Flower" && phenotype ? `${phenotype} ${category}` : category;

  const handleAddToBagClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onAddToBagClick && onAddToBagClick();
  };

  return (
    <div
      data-testid="menu-item-card-horizontal"
      className="relative h-full bg-white shadow-low rounded p-lg"
    >
      <Link
        href={menuItemUrl}
        onClick={handleCardClick}
        data-testid="menu-item-card-link"
      >
        <div className="grid grid-cols-[104px_1fr] md:grid-cols-[104px_1fr_152px] gap-x-md">
          <CardFlag
            menuCardIndicators={menuCardIndicators}
            isHorizontalCard={true}
          />
          <div className="pr-md w-[116px]">
            <Image
              src={thumbnailUrl}
              alt={name}
              sizes={[116]}
              ratio="square"
              data-testid="menu-item-card__image"
              imageClass="rounded"
            />
          </div>
          <div className="flex flex-col justify-start">
            <div className="text-secondary text-xs font-bold">
              {capitalize(productTypeText)}
            </div>
            {name && (
              <div className="font-bold text-sm break-words">{name}</div>
            )}
            {brandName && (
              <div className="flex items-center gap-xs">
                <div className="font-normal text-xs">by {brandName}</div>
                {isBrandVerified && (
                  <span>
                    <Verified
                      width="14"
                      height="14"
                      className="text-verified"
                    />
                  </span>
                )}
              </div>
            )}
            {(thcContentLabel || cbdContentLabel) && (
              <div className="flex flex-row font-bold text-xs gap-sm">
                {thcContentLabel && <div>{`THC ${thcContentLabel}`}</div>}
                {cbdContentLabel && <div>{`CBD ${cbdContentLabel}`}</div>}
              </div>
            )}
            {!!product?.avgApprovedPublicReviewRating && (
              <StarRating
                rating={product.avgApprovedPublicReviewRating}
                ratingCount={product.countApprovedPublicReviews}
                showRating={true}
                starWidth={14}
                data-testid="menu-item-card__rating"
              />
            )}
            <DispensaryMenuCardPriceData
              dealDiscountLabel={dealDiscountLabel}
              displayPrice={displayPrice}
              offerLabel={offerLabel}
              price={price}
              quantity={quantity}
              unit={unit}
              className="block md:hidden pt-sm"
            />
            {renderFulfillmentInfo && renderFulfillmentInfo(dispensary)}
          </div>
          <div className="hidden md:flex col-start-2 md:col-start-3 flex-col justify-end gap-sm">
            <DispensaryMenuCardPriceData
              dealDiscountLabel={dealDiscountLabel}
              displayPrice={displayPrice}
              offerLabel={offerLabel}
              price={price}
              quantity={quantity}
              unit={unit}
            />
            {useAlternateAddToBagAction ? (
              <Button
                data-testid="add-to-cart-button"
                iconFilePath="bag.svg"
                onClick={onAlternateAddToBagClick}
                className="w-full"
              >
                add to cart
              </Button>
            ) : (
              <MenuItemCardActions
                menuItem={
                  {
                    brandId: brand?.id,
                    dispensaryId: dispensary.id,
                    dispensaryInStoreCartEnabled: dispensary.inStoreCartEnabled,
                    dispensarySlug: dispensary.slug,
                    id: id,
                    name: name,
                    onlineFulfillmentEnabled: onlineFulfillmentEnabled,
                    productId: product?.id,
                    strainId: strain?.id,
                    variantId: variantId,
                  } as ATBMenuItem
                }
                eventCategory={eventCategory}
                onClick={handleAddToBagClick}
                className="w-full whitespace-nowrap self-end"
                onReservation={onReservation}
                menuItemUrl={menuItemUrl}
              />
            )}
          </div>
          {useAlternateAddToBagAction ? (
            <Button
              data-testid="add-to-cart-button"
              iconFilePath="bag.svg"
              onClick={onAlternateAddToBagClick}
              className="md:hidden flex justify-center items-center col-start-1 col-end-4 w-full whitespace-nowrap mt-lg"
            >
              add to cart
            </Button>
          ) : (
            <MenuItemCardActions
              menuItem={{
                brandId: brand?.id ?? null,
                dispensaryId: dispensary.id,
                dispensaryInStoreCartEnabled: dispensary.inStoreCartEnabled,
                dispensarySlug: dispensary.slug,
                id: id,
                name: name,
                onlineFulfillmentEnabled: onlineFulfillmentEnabled,
                productId: product?.id ?? null,
                strainId: strain?.id ?? null,
                variantId: variantId,
              }}
              onClick={handleAddToBagClick}
              onReservation={onReservation}
              className="md:hidden flex justify-center items-center col-start-1 col-end-4 w-full whitespace-nowrap mt-lg"
              menuItemUrl={menuItemUrl}
            />
          )}
        </div>
      </Link>
    </div>
  );
};

const DispensaryMenuCardPriceData: React.FC<{
  dealDiscountLabel: string | null;
  displayPrice: number;
  offerLabel: string | null;
  price: number;
  quantity: number;
  unit: string;
  className?: string;
}> = ({
  dealDiscountLabel,
  displayPrice,
  offerLabel,
  price,
  quantity,
  unit,
  className,
}) => {
  const countryCode = useDomainCountryCode();

  return (
    <div className={className}>
      <div className="flex items-center justify-start">
        <div
          className={classnames("font-bold text-md", {
            "text-notification": dealDiscountLabel,
          })}
          data-testid="menu-item-card__price"
        >
          {`${currencyFormatter(displayPrice, countryCode)}`}
        </div>
        {unit && quantity && (
          <div className="font-bold text-xs pl-xs">
            {formatQuantity({
              size: quantity,
              unit,
            })}
          </div>
        )}
      </div>
      {dealDiscountLabel && (
        <div data-testid="deal-section" className="flex items-center text-xs">
          <div
            data-testid="discount-label"
            className="bg-notification rounded text-white font-bold px-sm"
          >
            {dealDiscountLabel}
          </div>
          <span className="pl-sm text-grey">{`reg ${currencyFormatter(
            price,
            countryCode,
          )}`}</span>
        </div>
      )}
      {offerLabel && (
        <div data-testid="offer-section" className="whitespace-nowrap">
          <OfferLabel label={offerLabel} />
        </div>
      )}
    </div>
  );
};

export default DispensaryMenuItemCard;
