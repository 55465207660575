import React, { useId } from "react";
import classnames from "classnames";
import ContentLoader from "react-content-loader";

type SkeletonWrapperProps = {
  width: string;
  height: string;
  className?: string;
  children?: React.ReactNode;
};

const SkeletonWrapper: React.FC<SkeletonWrapperProps> = ({
  width,
  height,
  className,
  children,
}) => {
  const uniqueKey = useId();

  return (
    <ContentLoader
      speed={2}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      className={classnames(className)}
      uniqueKey={uniqueKey}
    >
      {children}
    </ContentLoader>
  );
};

export default SkeletonWrapper;
