import React from "react";

import SkeletonWrapper from "./SkeletonWrapper";

const StrainCardSkeleton: React.FC = () => (
  <SkeletonWrapper width="325" height="196">
    <rect x="12" y="12" rx="0" ry="0" width="64" height="64" />
    <rect x="84" y="12" rx="0" ry="0" width="96" height="28" />
    <rect x="84" y="44" rx="0" ry="0" width="228" height="32" />
    <rect x="84" y="84" rx="0" ry="0" width="164" height="24" />
    <rect x="84" y="114" rx="0" ry="0" width="196" height="24" />

    <rect x="92" y="172" rx="0" ry="0" width="141" height="24" />
  </SkeletonWrapper>
);

export default StrainCardSkeleton;
