import React, { ComponentProps } from "react";
import Link from "next/link";

import Button from "components/botanic/Button";

import { AddToBagButton } from "./AddToBagButton";

type MenuItemCardActionsProps = Omit<
  ComponentProps<typeof AddToBagButton>,
  "defaultVariantId"
> & {
  menuItemUrl: string;
  viewDetailsOnClick?: () => void;
};

export const MenuItemCardActions: React.FC<MenuItemCardActionsProps> = ({
  menuItem,
  menuItemUrl,
  viewDetailsOnClick = () => {},
  ...props
}) => {
  const { dispensaryInStoreCartEnabled, onlineFulfillmentEnabled, variantId } =
    menuItem;

  return onlineFulfillmentEnabled || dispensaryInStoreCartEnabled ? (
    <AddToBagButton
      {...props}
      defaultVariantId={variantId}
      menuItem={menuItem}
    />
  ) : (
    <Link href={menuItemUrl} passHref legacyBehavior>
      <Button
        variant="secondary"
        onClick={viewDetailsOnClick}
        className="py-[6px]"
        href={menuItemUrl}
        data-testid="view-details-button"
        {...props}
      >
        view details
      </Button>
    </Link>
  );
};
