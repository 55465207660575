import React from "react";

import SkeletonWrapper from "./SkeletonWrapper";

const PickupReadinessCardSkeleton: React.FC = () => (
  <SkeletonWrapper width="104" height="20">
    <rect x="0" y="0" rx="3" ry="3" width="104" height="20" />
  </SkeletonWrapper>
);

export default PickupReadinessCardSkeleton;
